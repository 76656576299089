.prepare {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  margin-top: 20px;
}
.prepare .title {
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
}
.prepare .left {
    width: 810px;
}
.prepare .left .train-paln-info {
      height: 200px;
      background: #ffffff;
      border: #e5e5e5 1px solid;
      padding: 20px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.prepare .left .train-paln-info .info-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.prepare .left .train-paln-info .info-wrap .line {
          display: inline-block;
          width: 2px;
          height: 110px;
          background: #e5e5e5;
}
.prepare .left .train-paln-info .info-wrap .plan-list {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
}
.prepare .left .train-paln-info .info-wrap .manager-role {
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
}
.prepare .left .train-paln-info .info-wrap .manager-role li {
            height: 28px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
}
.prepare .left .train-paln-info .info-wrap .manager-role li .verify {
              width: auto;
              color: red;
}
.prepare .left .train-paln-info .info-wrap .manager-role li > span {
              width: 220px;
}
.prepare .left .train-paln-info .info-wrap .manager-role li > span .label {
                display: inline-block;
                width: 120px;
                margin-right: 10px;
}
.prepare .left .train-paln-info .info-wrap .manager-role li > span span {
                width: 100px;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
}
.prepare .left .train-paln-info .info-wrap .manager-role li button {
              padding: 0px;
}
.prepare .left .el-tree-node {
      margin: 10px 0;
}
.prepare .left .class-select {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin-top: 8px;
}
.prepare .left .class-select .choose,
      .prepare .left .class-select .selected {
        padding: 20px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        width: 398px;
        background: #ffffff;
        border: #e5e5e5 1px solid;
}
.prepare .left .class-select .choose .node-text,
        .prepare .left .class-select .selected .node-text {
          width: 288px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
}
.prepare .left .class-select .choose .node-text .close,
          .prepare .left .class-select .selected .node-text .close {
            position: absolute;
            right: 0;
}
.prepare .left .class-select .choose .el-tree-node__content,
        .prepare .left .class-select .selected .el-tree-node__content {
          height: auto;
          -webkit-box-align: baseline;
              -ms-flex-align: baseline;
                  align-items: baseline;
}
.prepare .left .class-select .choose .el-tree-node__content:hover,
          .prepare .left .class-select .selected .el-tree-node__content:hover {
            background-color: #f5f7fa;
}
.prepare .left .class-select .choose .el-tree-node__content .el-icon-error:hover,
          .prepare .left .class-select .selected .el-tree-node__content .el-icon-error:hover {
            color: red;
}
.prepare .right {
    width: 380px;
}
.prepare .right .start-button {
      margin-bottom: 10px;
}
.prepare .right .history-list-wrap {
      border: #e5e5e5 1px solid;
      background: #ffffff;
      padding: 20px;
      padding-top: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.prepare .right .history-list-wrap .history-list > li {
        border-bottom: #e5e5e5 1px solid;
        padding-bottom: 20px;
}
.prepare .right .history-list-wrap .history-list .info-list {
        margin-top: 20px;
}
.prepare .right .history-list-wrap .history-list .info-list .status {
          margin-left: 18px;
}
.prepare .right .history-list-wrap .history-list .info-list .red {
          color: #f43030;
}
.prepare .right .history-list-wrap .history-list .info-list .blue {
          color: #308c86;
}
.prepare .right .history-list-wrap .history-list .info-list .btns {
          margin-top: 10px;
}
.prepare .right .history-list-wrap .history-list .info-list .btns .el-button--small {
            font-size: 14px;
            padding: 8px 11px;
}
.prepare .el-tree-node {
    margin: 15px 0;
}
.prepare .el-tree-node .node-label,
    .prepare .el-tree-node .el-tree-node__label {
      word-break: normal;
      width: 280px;
      display: block;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 17px;
}
.prepare .el-pagination {
    text-align: center;
    margin-top: 10px;
}
.prepare .el-dialog__header {
    border-bottom: #e5e5e5 1px solid;
    padding: 10px 20px 10px;
}
.prepare .el-dialog__body .no-data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.prepare .el-dialog__body .no-data-text {
    margin-top: 35px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}
.prepare .classroom-style span {
    font-size: 10px;
    color: #333333;
    margin-right: 20px;
}
.prepare .classroom-style span i {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 2px;
}
.prepare .classroom-style span i.selecte {
        background: #e5e5e5;
}
.prepare .classroom-style span i.selected {
        background: #409eff;
}
.prepare .classroom-style span i.using {
        background: #f32938;
}
.prepare .room-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.prepare .room-list li {
      width: 60px;
      height: 60px;
      background: #e5e5e5;
      margin: 15px;
      text-align: center;
      color: #333333;
      font-size: 12px;
      padding: 10px;
      line-height: 22px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      overflow: hidden;
}
.prepare .room-list li.blue {
        background: #409eff;
        color: #ffffff;
}
.prepare .room-list li.red {
        background: #f32938;
        color: #ffffff;
}
.prepare .room-list li:hover {
        background: #409eff;
        color: #ffffff;
        cursor: pointer;
}
